@media (max-width: 1200px) {
  .header {
    padding: 8px;
  }
  .mtz-d-lg-none {
    display: none !important;
  }
}

/* Desktop override: force dropdowns within the header container to be static 
   so that the absolute positioning of .zoom-dropdown is calculated from the header container */
@media (min-width: 1200px) {
  .header-container .dropdown {
    position: static !important;
  }
}

@media (min-width: 1200px) {
  /* Target only the Products and Solutions dropdown menus by their aria-labelledby attribute */
  .dropdown-menu[aria-labelledby="productsDropdown"] a,
  .dropdown-menu[aria-labelledby="solutionsDropdown"] a {
    color: #000; /* Change to desired color (black here) */
    /* Leave margin and padding intact */
  }

  /* Add a subtle background highlight on hover */
  .dropdown-menu[aria-labelledby="productsDropdown"] a:hover,
  .dropdown-menu[aria-labelledby="solutionsDropdown"] a:hover {
    background-color: #e9ecef; /* Light gray hover background; adjust if needed */
    text-decoration: none; /* Remove underline on hover */
  }
}

/* Base styles */
.bg-white {
  background-color: #fff;
}

.border {
  border: 1px solid #ddd;
}

/* The header container provides a common positioning context */
.header-container {
  position: relative;
}

/* Zoom-like dropdown styling with centered alignment and fixed width */
.zoom-dropdown {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 10px); /* Positioned below the button with a gap */
  width: 950px !important; /* Fixed width for every dropdown */
  min-width: 320px; /* Minimum width for a spacious look */
  background-color: #fff; /* Clean white background */
  border: 1px solid #e0e0e0; /* Light border */
  border-radius: 4px; /* Slightly rounded corners */
  padding: 10px; /* Space around dropdown content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle drop shadow */
  z-index: 1000; /* Ensure it appears above other content */
}

/* Styling for texts inside the dropdown */
.zoom-dropdown p {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  color: #333;
}

/* New styling for dropdown section titles */
.dropdown-title {
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.zoom-dropdown h5 {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #222;
}
