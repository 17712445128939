/* generals */
.btn.active {
	color: #3772ff !important;
}

/* cards */
.mtz-card {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 16px;
	border-radius: 16px;
	background: white;
}

.mtz-card-box {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 16px;
	background: white;
}

/* border radius */
.mtz-rounded-4 {
	border-radius: 4px !important;
}

.mtz-rounded-8 {
	border-radius: 8px !important;
}

.mtz-rounded-12 {
	border-radius: 12px !important;
}

.mtz-rounded-16 {
	border-radius: 16px !important;
}

/* .mtz-rounded-left-16 {
	border-: 16px !important;
}

.mtz-rounded-bottom-16 {
	border-radius: 16px !important;
}

.mtz-rounded-top-16 {
	border-radius: 16px !important;
}

.mtz-rounded-top-16 {
	border-radius: 16px !important;
} */

/* padding */
.mtz-p-4 {
	padding: 4px;
}

.mtz-p-8 {
	padding: 8px;
}

.mtz-p-16 {
	padding: 16px;
}

.mtz-px-16 {
	padding-left: 16px;
	padding-right: 16px;
}

.mtz-p-24 {
	padding: 24px !important;
}

.mtz-p-32 {
	padding: 32px !important;
}

.mtz-pt-16 {
	padding-top: 16px;
}

.mtz-py-8 {
	padding-top: 8px;
	padding-bottom: 8px;
}

.mtz-py-16 {
	padding-top: 16px;
	padding-bottom: 16px;
}

.mtz-py-24 {
	padding-top: 24px;
	padding-bottom: 16px;
}

/* margin */
.mtz-m-4 {
	margin: 4px;
}

.mtz-mb-4 {
	margin-bottom: 4px;
}

.mtz-m-8 {
	margin: 8px;
}

.mtz-m-16 {
	margin: 16px;
}

.mtz-mb-16 {
	margin-bottom: 16px;
}

.mtz-mt-16 {
	margin-top: 16px;
}

/* headers */
.mtz-h1 {
	font-family: 'Satoshi' !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 56px !important;
}

.mtz-h2 {
	font-family: 'Satoshi' !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 40px !important;
}

.mtz-h3 {
	font-family: 'Satoshi' !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 32px !important;
}

.mtz-h4 {
	font-family: 'Satoshi' !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 24px !important;
}

.mtz-h5 {
	font-family: 'Satoshi' !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 20px !important;
}

.mtz-h6 {
	font-family: 'Satoshi' !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 16px !important;
}

/* body */

.mtz-b1 {
	font-family: 'Satoshi' !important;
	font-style: normal !important;
	font-size: 16px !important;
}

.mtz-b2 {
	font-size: 14px;
}

.mtz-b3 {
	font-size: 12px;
}

/* flex gap || flex spacing */
.mtz-gap-16 {
	gap: 20px;
}

.mtz-gap-16 {
	gap: 16px;
}

.mtz-gap-8 {
	gap: 8px;
}

.mtz-gap-4 {
	gap: 4px;
}

/* buttons */
.mtz-btn-sm {
	height: 25px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 16px;
}

.mtz-btn {
	height: 35px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 16px;
	white-space: nowrap;
}

.mtz-btn-lg {
	height: 45px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 16px;
}

.mtz-btn:hover {
	opacity: .8;
}

.btn-hover-light:hover {
	background: #f1f1f1;
}

.mtz-box-button:hover {
	background: #e9f0ff !important;
}

/* text */
.mtz-text-primary {
	color: #3772ff !important;
}

.overflow-y {
	overflow-y: scroll;
}

.overflow-x {
	overflow-x: scroll;
}

.ql-toolbar {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.ql-container {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    max-height: 400px;
    overflow-y: auto;
    font-size: 14px;
    height: auto !important;
    min-height: 50px !important;
}

.ql-editor[contenteditable=false] {
    min-height: 50px !important;
    height: auto !important;
}

.ql-editor[contenteditable=true] {
    min-height: 100px !important;
    height: auto !important;
}