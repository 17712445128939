/* #5E2FFF */

.alert {
	border-radius: 16px;
}

.modal-content {
	box-shadow: 2px 2px 4px;
	border-radius: 16px;
}

/* .btn {
	border-radius: 8px;
} */

.text-warning {
	color: #FFA337 !important;
}

.border-2 {
	border-width: 2px !important;
}

.border-3 {
	border-width: 3px !important;
}

.border-4 {
	border-width: 4px !important;
}

.border-5 {
	border-width: 5px !important;
}

.bg-primary {
	background: #3772ff !important;
}

.bg-light {
	background: #f1f1f1 !important;
}

.btn-primary:hover,
.btn-danger:hover,
.btn-warning:hover,
.btn-success:hover,
.btn-light:hover,
.btn-secondary {
	opacity: .9 !important;
}

.btn-outline-success:hover {
	color: white !important;
}

.vw-25 {
	width: 25vw !important;
}

.vw-50 {
	width: 50vw !important;
}

@media (min-width: 768px) {
	.vw-md-100 {
		width: 100vw !important;
	}

	.vw-md-auto {
		width: auto !important;
	}
}

@media (min-width: 1200px) {
	.modal-xxl {
		max-width: 1600px;
	}
}