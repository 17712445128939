@media (min-width: 1200px) {
    /* .logo {
        width: 380px;
    } */

    /* .right-buttons {
        width: 400px;
    } */

    .searchbox {
        width: 850px;
    }
}


@media (max-width: 1200px) {
    .header {
        padding: 8px;
    }

    .searchbox {
        width: 100%;
    }
}