/* fonts */
@font-face {
	font-family: "Satoshi";
	src: url(/assets/fonts/Satoshi-Regular.ttf) format("truetype");
}

@font-face {
	font-family: "AbhayaLibre";
	src: url(/assets/fonts/AbhayaLibre-Regular.ttf) format("truetype");
}

@font-face {
	font-family: "Arimo";
	src: url(/assets/fonts/Arimo-Regular.ttf) format("truetype");
}

@font-face {
	font-family: "Courier";
	src: url(/assets/fonts/Courier-Prime.ttf) format("truetype");
}

@font-face {
	font-family: "OpenSans";
	src: url(/assets/fonts/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
	font-family: "Rubik";
	src: url(/assets/fonts/Rubik-Regular.ttf) format("truetype");
}

@font-face {
	font-family: "Selawk";
	src: url(/assets/fonts/Selawk.ttf) format("truetype");
}

@font-face {
	font-family: "UbuntuMono";
	src: url(/assets/fonts/UbuntuMono-R.ttf) format("truetype");
}

* {
	box-sizing: border-box !important;
	font-family: Satoshi, sans-serif;
}

/* npm css */
@import "~react-image-gallery/styles/css/image-gallery.css";

/* custom css */
label {
	font-weight: bold !important;
}

.fa {
	font-family: FontAwesome !important;
}

.fas {
	font-family: FontAwesome !important;
}

html {
	scroll-behavior: smooth;
}

.min-height-100 {
	min-height: 100% !important;
}

.modal {
	z-index: 1101 !important;
}

.popover {
	z-index: 1102 !important;
}

.text-danger {
	color: #BD2130 !important;
}

h5 {
	font-weight: bolder;
}

th {
	font-weight: bolder !important;
}

/* MATERIAL-UI fixes */
.MuiButton-containedSecondary {
	background: #BD2130
}

.MuiButton-label {
	text-transform: none;
}

.MuiTablePagination-root .MuiSelect-root {
	line-height: 2 !important;
}

.MuiInputBase-input,
.MuiInput-input {
	height: 40px !important;
}

.ql-editor {
	min-height: 300px;
}

.mtz-cursor-pointer {
	cursor: pointer !important;
}

.text-transform-none {
	text-transform: none !important;
}

.top-0 {
	top: 0;
}

.global-disabled {
	pointer-events: none;
	opacity: .75;
}

.z-index-1 {
	z-index: 1;
}

.z-index-10000 {
	z-index: 10000;
}

.right-0 {
	right: 0;
}

.hover:hover {
	opacity: 0.75;
}

.hover-light:hover {
	background: #eee
}

.left-0 {
	left: 0;
}

.font-30 {
	font-size: 30px;
}

.w-20 {
	width: 20% !important;
	transition: 1s width;
}

.w-80 {
	width: 80% !important;
	transform: 1s width;
}

.mvh-100 {
	min-height: 100vh;
}

.vh-90 {
	height: 90vh !important;
}

.vh-75 {
	height: 75vh !important;
}

.vh-50 {
	height: 50vh !important;
}

.vh-25 {
	height: 25vh !important;
}

.mh-auto {
	min-height: initial !important;
}

.mw-25 {
	max-width: 25% !important;
}

.MuiCardHeader-root,
.MuiCardContent-root {
	padding: 10px !important;
}

@media screen and (min-width: 768px) {
	.w-md-20 {
		width: 20% !important;
		transition: 1s width;
	}

	.w-md-80 {
		width: 80% !important;
	}

	.w-md-50 {
		width: 50% !important;
		transition: 1s width;
	}

	.w-md-25 {
		width: 25% !important;
		transition: 1s width;
	}

	.w-md-75 {
		width: 75% !important;
	}
}

@media screen and (min-width: 992px) {
	.w-lg-20 {
		width: 20% !important;
	}

	.w-lg-80 {
		width: 80% !important;
	}

	.w-lg-25 {
		width: 25% !important;
	}

	.w-lg-50 {
		width: 50% !important;
	}

	.w-lg-75 {
		width: 75% !important;
	}

	.w-lg-100 {
		width: 100% !important;
	}
}

@media screen and (min-width: 1200px) {
	.w-xl-20 {
		width: 20% !important;
	}

	.w-xl-80 {
		width: 80% !important;
	}

	.w-xl-25 {
		width: 25% !important;
	}

	.w-xl-50 {
		width: 50% !important;
	}

	.w-xl-75 {
		width: 75% !important;
	}

	.w-xl-100 {
		width: 100% !important;
	}
}

.horizontal-line {
	width: 100%;
	height: 2px;
	background-color: #999;
}

hr {
	width: 100%;
	border: 1px solid #ddd;
}