.WithThreeColumnLayout {
    margin-top: 8px;
}

.WithThreeColumnLayout-content {
    margin-bottom: 50px;
}

.WithThreeColumnLayout-left-sidebar {
    height: calc(100vh - 100px)
}

.WithThreeColumnLayout-right-sidebar {
    height: calc(100vh - 100px)
}

@media (max-width: 1200px) {
    .WithThreeColumnLayout-content {
        width: 100% !important;
        max-width: 100vw !important;
    }
}